// src/ContactForm.js
import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './ContactForm.css'; // Import the CSS file for styling
import { uploadFileToBlob } from './azureBlobService'; // Import the Azure Blob service helper
import Notification from './Notification'; // Import the Notification component

const ContactForm = () => {
  const formRef = useRef();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState(null);

  const validateForm = () => {
    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
    const newErrors = {};

    // Check if the email is valid
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(data.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = new FormData(formRef.current);
      const data = Object.fromEntries(formData.entries());
      const attachments = formData.getAll('attachments').filter(file => file.size > 0);

      // Generate UUID and timestamp
      const messageId = uuidv4();
      const createdAt = new Date().toISOString();

      let fileUrls = [];
      if (attachments.length > 0) {
        // Upload files to Azure Blob Storage and get their URLs
        const fileUploadPromises = attachments.map(file => uploadFileToBlob(file));
        fileUrls = await Promise.all(fileUploadPromises);
      }

      const payload = {
        ...data,
        id: messageId,
        created_at: createdAt,
        attachments: fileUrls // Array of file URLs or empty array
      };

      setIsSubmitting(true);

      try {
        const response = await fetch('https://prod-165.westus.logic.azure.com:443/workflows/4bc0df07e819445fba2e7ae9f3bc1948/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=nZDus9FMKuwBkiqxCHlPIAZABA6AvpZJriMjqL05peU', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (response.ok) {
          setNotification('Message sent successfully!');
          setTimeout(() => {
            window.location.reload();
          }, 2000); // 2 seconds delay before redirect
        } else {
          setNotification('Failed to send message. Please try again.');
        }
      } catch (error) {
        console.error('Error:', error);
        setNotification('An error occurred. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="container">
      <h1>Contact Us</h1>
      <p>Experience the future of AI automation solutions. Get in touch with us today.</p>

      <form ref={formRef} id="contact-form" onSubmit={handleSubmit}>
        {/* Contact Information */}
        <h2>Contact Information</h2>
        <div className="input-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="input-group">
          <label htmlFor="phone">Phone Number</label>
          <input type="text" id="phone" name="phone" required />
        </div>
        <div className="input-group">
          <label htmlFor="company">Company Name</label>
          <input type="text" id="company" name="company" required />
        </div>

        {/* Project Information */}
        <h2>Project Information</h2>
        <div className="input-group">
          <label htmlFor="project_summary">Project Summary</label>
          <input type="text" id="project_summary" name="project_summary" required />
        </div>
        <div className="input-group">
          <label htmlFor="detailed_description">Detailed Description</label>
          <textarea id="detailed_description" name="detailed_description" required></textarea>
        </div>
        <div className="input-group">
          <label htmlFor="priority">Priority Level</label>
          <select id="priority" name="priority" required>
            <option value="Critical">Critical</option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="start_date">Preferred Start Date</label>
          <input type="date" id="start_date" name="start_date" required />
        </div>

        {/* Technical Details */}
        <h2>Technical Details (if known)</h2>
        <div className="input-group">
          <label htmlFor="current_systems">Current Systems and Tools</label>
          <textarea id="current_systems" name="current_systems"></textarea>
        </div>
        <div className="input-group">
          <label htmlFor="trigger_details">Trigger Details</label>
          <select id="trigger_details" name="trigger_details" multiple>
            <option value="Schedule">Schedule</option>
            <option value="Event">Event</option>
            <option value="Status Change">Status Change</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="conditions">Conditions</label>
          <textarea id="conditions" name="conditions"></textarea>
        </div>
        <div className="input-group">
          <label htmlFor="actions">Actions</label>
          <textarea id="actions" name="actions"></textarea>
        </div>
        <div className="input-group">
          <label htmlFor="expected_outcome">Expected Outcome</label>
          <textarea id="expected_outcome" name="expected_outcome"></textarea>
        </div>

        {/* Attachments */}
        <h2>Attachments (if any)</h2>
        <div className="input-group">
          <label htmlFor="attachments">Attachments</label>
          <input type="file" id="attachments" name="attachments" multiple />
        </div>

        {/* Additional Information */}
        <h2>Additional Information</h2>
        <div className="input-group">
          <label htmlFor="additional_info">Additional Information</label>
          <textarea id="additional_info" name="additional_info"></textarea>
        </div>

        <button type="submit" className="btn" disabled={isSubmitting}>
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
      </form>
      {notification && <Notification message={notification} onClose={() => setNotification(null)} />}
    </div>
  );
};

export default ContactForm;
