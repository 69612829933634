// src/azureBlobService.js
import { BlobServiceClient } from '@azure/storage-blob';

// Azure Blob Storage configuration
const containerName = 'attachments';
const sasToken = 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-08-25T13:50:05Z&st=2024-07-17T05:50:05Z&spr=https&sig=LyT3jYajgcPDMNm5rfygNxRHLkeH5ISybh0ArCWtMOA%3D';
const storageAccountName = 'contactformtwo';

const uploadFileToBlob = async (file) => {
  if (!file) return null;

  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
  );

  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blobClient = containerClient.getBlockBlobClient(file.name);
  await blobClient.uploadData(file);

  return blobClient.url;
};

export { uploadFileToBlob };
