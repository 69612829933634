import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import './App.css';
import logo from './logo.png';  // Replace with the actual path to your logo
import ContactForm from './ContactForm';

const App = () => {
  const canvasRef = useRef();

  useEffect(() => {
    // WebGL Background Animation
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setClearColor(0x000000, 0); // Making the background of the canvas transparent
    renderer.setSize(window.innerWidth, window.innerHeight);
    canvasRef.current.appendChild(renderer.domElement);

    // Load the logo as a texture
    const textureLoader = new THREE.TextureLoader();
    const logoTexture = textureLoader.load(logo);

    // Create a cylinder geometry to simulate a disc and apply the logo texture
    const geometry = new THREE.CylinderGeometry(10, 10, 1, 32);
    const material = new THREE.MeshBasicMaterial({ map: logoTexture, transparent: true });
    const disc = new THREE.Mesh(geometry, material);
    scene.add(disc);

    camera.position.z = 30;

    const animate = () => {
      requestAnimationFrame(animate);
      disc.rotation.x += 0.01;
      disc.rotation.y += 0.01;
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      // Clean up WebSocket connection if needed
    };
  }, []);

  return (
    <div>
      <div ref={canvasRef} id="canvas-container"></div>
      <ContactForm />
    </div>
  );
};

export default App;
